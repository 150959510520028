import React from 'react';
import Particles from 'react-particles-js';
import './Home.scss';
import {ButtonToolbar, Button} from 'react-bootstrap';
import logo from '../../img/logo.png';

const particlesOptions = {
      "particles": {
          "number": {
              "value": 120,
          },
          "size": {
              "value": 2
          }
      },
      "interactivity": {
          "events": {
              "onclick": {
                  "enable": true,
                  "mode": "remove"
              }
          }
      }
  };

class Particle extends React.Component{
    render(){
        return(
            <div className="particleBox">
                <Particles className="particles"
                    params={particlesOptions}
                />
                <div className="particleContext">
                <img className="logoImg" src={logo} alt="brain mirchi"/>
                <h2 className="text-center headingText">EMPOWERING BUSINESSES THROUGH</h2>
                <h1 className="text-center headText">Creative, Strategic & ROI Driven Solutions</h1>
                <ButtonToolbar>
                    <Button className="ctaBtn">Let's Discuss Your Project</Button>
                </ButtonToolbar>
            </div>
            </div>
        );
    }
}
export default Particle;