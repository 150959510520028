import React from 'react';
import {Row, Col, ButtonToolbar, Button} from 'react-bootstrap';
import './Banner.scss';

class Banner2 extends React.Component{
    render(){
        return(
            <div className="bannerBox">
                <Row>
                    <Col md={6} >
                        <div className="contextBox">
                        <h1 className="headText">About us</h1>
                        <p className="conText">We are a full house digital branding & Development company based in New Delhi providing Mobile App, Web Design & Web Development. Being one of the best Digital company in New Delhi We assure time bound services to our customers and support them in developing winning strategies for their businesses through innovative technologies. We at BrainMirchi believe in the passion about bringing more with a merciless search of triumph, we begin where other agencies stop.</p>
                        <ButtonToolbar>
                            <Button className="ctaBtn2">Get in Touch</Button>
                        </ButtonToolbar>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Banner2;