import React from 'react';
import { BrowserRouter as Router, Route} from 'react-router-dom';
import './App.scss';
import Home from './Components/Home/Home';
import Contact from './Components/Contact/Contact';
import Footer from './Components/Layout/Footer';


function App() {
  return (
    <div className="App">
      <Router>
      <Route path="/" exact component={Home} />
      <Route path="/contact/" component={Contact} />
      <Footer/>
      </Router>
    </div>
  );
}

export default App;
