import React from 'react';
import handImg from '../../img/hand.png'

class Handbox extends React.Component{
    render(){
        return(
            <div className="handArea">
            <div className="mainText">Get in Touch
            </div>
            <div className="border1"></div>
            <div className="border2"></div>
            <div className="border3"></div>
                <img className="handImg" src={handImg} alt="hand img" />
            </div>
        );
    }
}
export default Handbox;