import React from 'react';
import { Row, Col, Carousel } from 'react-bootstrap';

class Testimonial extends React.Component{
    render(){
        return(
            <div className="testimonialArea">
                <Row>
                    <Col md={6} className="leftArea">
                        <div className="headArea">
                        <div className="headText">What People say about us</div>
                        </div>
                    </Col>

                    <Col md={6} className="rightArea">
                    <Carousel className="contextSlider">
                        <Carousel.Item>
                            <h3 className="headText">Mr. Iqbal</h3>
                            <p className="conText"> I really appreciate everything that they did for creating a new corporate website. BrainMirchi provided me their best support and I always get solutions.</p>
                        </Carousel.Item>

                        <Carousel.Item>
                            <h3 className="headText">Mr. Alex</h3>
                            <p className="conText"> We have been using BrainMirchi service for the past 1 years and are happy with the quality of service they provide, web development.</p>
                        </Carousel.Item>

                        <Carousel.Item>
                            <h3 className="headText">Mr. Anil</h3>
                            <p className="conText"> After sitting with various web development companies around New Delhi, BrainMirchi stood out from the rest.</p>
                        </Carousel.Item>
                    </Carousel>
                    </Col>
                </Row>
            </div>
        );
    }
}
export default Testimonial;