import React from 'react';
import { Row, Col } from 'react-bootstrap';

class Clients extends React.Component{
    render(){
        return(
            <div className="clientArea">
                <Row>
                    <Col md={12}>
                        <div className="headArea">
                            <h2 className="headText">Who we've helped.</h2>
                        </div>
                    </Col>
                    <Col md={2}>
                            <img className="clientLogo" src="https://www.aigtechnologies.in/images/partners/aig-client-car-wash.jpg" alt="clients logo" />
                    </Col>
                    <Col md={2}>
                            <img className="clientLogo" src="https://www.aigtechnologies.in/images/partners/aig-client-car-wash.jpg" alt="clients logo" />
                    </Col>
                    <Col md={2}>
                            <img className="clientLogo" src="https://www.aigtechnologies.in/images/partners/aig-client-car-wash.jpg" alt="clients logo" />
                    </Col>
                    <Col md={2}>
                            <img className="clientLogo" src="https://www.aigtechnologies.in/images/partners/aig-client-car-wash.jpg" alt="clients logo" />
                    </Col>
                    <Col md={2}>
                            <img className="clientLogo" src="https://www.aigtechnologies.in/images/partners/aig-client-car-wash.jpg" alt="clients logo" />
                    </Col>
                    <Col md={2}>
                            <img className="clientLogo" src="https://www.aigtechnologies.in/images/partners/aig-client-car-wash.jpg" alt="clients logo" />
                    </Col>
                </Row>
            </div>
        );
    }
}
export default Clients;