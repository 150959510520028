import React from 'react';
import {Row, Col} from 'react-bootstrap';
import rightArrow from '../../img/right-arrow.svg';
import facebook from '../../img/facebook.svg';
import linkedin from '../../img/linkedin.svg';
import instagram from '../../img/instagram.svg';
import whatsapp from '../../img/whatsapp.svg';


class Footer extends React.Component{
    render(){
        return(
            <div className="footerArea">
                {/* <Container> */}
                <Row className="footerBox">
                    <Col md={3}>
                        <h2 className="highliteText">We'd love to hear about your digital project. Please get in touch with one of our Project Consultants.</h2>
                    </Col>
                    <Col md={3}>
                        <div className="mailText"> <span> <img className="arrowImg" src={rightArrow} alt="Brain Mirchi"/> </span> hello@brainmirchi.com</div>
                        <div className="socialBox">
                            <span >
                            <img className="socialIcon" src={facebook} alt="fb" />
                            </span>
                            <span >
                            <img className="socialIcon" src={linkedin} alt="fb" />
                            </span>
                            <span >
                            <img className="socialIcon" src={instagram} alt="fb" />
                            </span>
                            <span >
                            <img className="socialIcon" src={whatsapp} alt="fb" />
                            </span>
                        </div>
                    </Col>
                    <Col md={3}>
                        <p className="aboutText">BrainMirchi is a premier web design and web development company with Based in New Delhi.</p>
                    </Col>
                    <Col md={3}>
                        <div className="copyText">Copyright 2019 Brain Mirchi.</div>
                        <div className="copyText">All rights reserved.</div>
                        <div className="copyText linkText">Terms and Conditions</div>
                        <div className="copyText linkText">Privacy Policy</div>
                    </Col>
                </Row>
                {/* </Container> */}
            </div>
        );
    }
}
export default Footer;