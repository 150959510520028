import React from 'react';
import './Layout.scss';
import {Row, Col} from 'react-bootstrap';

class Services extends React.Component{
    render(){
        return(
            <div className="serviceArea">
                <Row>
                    <Col md={3} className="wdBox">
                        
                        <div className="serviceContext">
                            <h1 className="headText">Web Designing</h1>
                        </div>
                        
                    </Col>
                    <Col md={3} className="wdmBox">
                    
                        <div className="serviceContext2">
                            <h1 className="headText">Web Development</h1>
                        </div>
                        
                    </Col>
                    <Col md={3} className="adBox">
                    
                        <div className="serviceContext">
                            <h1 className="headText">App Development </h1>
                        </div>
                        
                    </Col>
                    <Col md={3} className="dmBox">
                    
                        <div className="serviceContext2">
                            <h1 className="headText">Digital Marketing</h1>
                        </div>
                        
                    </Col>
                </Row>
            </div>
        );
    }
}
export default Services;