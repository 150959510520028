import React from 'react';
import Helmet from 'react-helmet';
import {Form, Button} from 'react-bootstrap';



class Contact extends React.Component{
    render(){
        return(
            <div>
                 <Helmet>
                    <title>Contact</title>
                    <meta name="description" content="Contact page"/>
                </Helmet>

                <Form name="contact" netlify>
  <Form.Group controlId="formBasicEmail">
    <Form.Label>Email</Form.Label>
    <Form.Control type="email" name="email" placeholder="email" />
    <Form.Text className="text-muted">
      We'll never share your email with anyone else.
    </Form.Text>
  </Form.Group>

  <Form.Group controlId="formBasicPassword">
    <Form.Label>Name</Form.Label>
    <Form.Control type="text" name="name" placeholder="name" />
  </Form.Group>

  <Button variant="primary" type="submit">
    Submit
  </Button>
</Form>
            </div>
        );
    }
}
export default Contact;