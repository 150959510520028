import React from 'react';
import Helmet from 'react-helmet';
// import CTA from '../Layout/Cta';
// import Main from '../Banner/Main';
import Banner2 from '../Banner/Banner2';
import Services from '../Layout/Services';
import Testimonial from '../Layout/Testimonial';
import Clients from '../Layout/Clients';
import Handbox from '../Layout/Handbox';
import Particle from './Particle';


class Home extends React.Component{
    render(){
        return(
            <div>
                <Helmet>
                    <title>Home App</title>
                    <meta name="description" content="Home page"/>
                </Helmet>

                        {/* <Main/> */}
                        <Particle/>
                        <Banner2/>
                        <Services/>
                        <Clients/>
                        <Testimonial/>
                        <Handbox/>
                        {/* <CTA/> */}
  
            </div>
        );
    }
}
export default Home;